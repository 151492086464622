<template>
  <div class="layout" >
    <div
        class="card-staff"
        v-for="(item, index) in listHonors"
        :key="index"
    >
      <CardStaffItem :staff="item"/>
    </div>
  </div>
</template>

<script>
import CardStaffItem from "@/views/components/honors/CardStaffItem.vue";
import {HonorModel} from "@/views/pages/honors/Model";

export default {
  components: {CardStaffItem},
  props: {
    listHonorStaff: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [
        {
          image: '',
          name: '',
          title: '',
          apartment: '',
          content: '',
          heart: 0,
          share: 0,
        }
      ]
    }
  },
  data() {
    return {
      listHonors: [],
    }
  },
  mounted() {
    this.convertToModel();
  },
  methods: {
    convertToModel() {
      this.listHonors = this.listHonorStaff.map((item) => {
        return new HonorModel(
            item?.id,
            item?.category_name,
            item?.newsletter_desc,
            item?.newsletter_like,
            item?.newsletter_share,
            item?.content,
            item?.attach_files[0],
            item?.department,
            item?.user_like?.like === 1 ? 1 : 0,
            item?.user_like?.share > 0
        )
      })
    }
  }
}
</script>

<style lang="css" scoped>
.layout {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .layout {
    gap: 10px;
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .layout {
    grid-template-columns: 1fr;
  }
  .card-staff {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1025px) {
  .card-staff {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
}
</style>