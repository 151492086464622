import { render, staticRenderFns } from "./HonorPage.vue?vue&type=template&id=e88112be&scoped=true&"
import script from "./HonorPage.vue?vue&type=script&lang=js&"
export * from "./HonorPage.vue?vue&type=script&lang=js&"
import style0 from "./HonorPage.vue?vue&type=style&index=0&id=e88112be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e88112be",
  null
  
)

export default component.exports