<template>
  <div>
    <div class="card-apartment" v-for="(apartment, index) in listHonors" :key="index">
      <CardAnimation>
        <img @click="redirectToHonorDetail(apartment.id)" class="img-apartment" :src="apartment?.image"
             alt="apartment-image" />
        <div class="apartment-action">
          <ActionComponent :post="apartment" type-post="apartment"/>
        </div>
      </CardAnimation>
    </div>
  </div>
</template>

<script>
import ActionComponent from "@/views/components/honors/ActionComponent.vue";
import { HonorModel } from "@/views/pages/honors/Model";
import CardAnimation from "@/views/components/honors/CardAnimation.vue";

export default {
  components: {CardAnimation, ActionComponent },
  props: {
    listApartment: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
      listHonors: []
    }
  },
  mounted() {
    this.convertToModel();
  },
  methods: {
    redirectToHonorDetail(idApartment) {
      this.$router.push({
        name: 'honor-detail',
        params: { id: idApartment },
        query: { type: 'apartment' }
      })
    },
    convertToModel() {
      this.listHonors = this.listApartment.map((item, index) => {
        return new HonorModel(
            item?.id,
            item?.category_name,
            item?.newsletter_desc,
            item?.newsletter_like,
            item?.newsletter_share,
            item?.content,
            item?.attach_files[0],
            item?.department,
            item?.user_like?.like === 1 ? 1 : 0,
            item?.user_like?.share > 0
        )
      })
    }
  }
}
</script>

<style lang="css" scoped>
.card-apartment {
  cursor: pointer;
  position: relative;
}

.img-apartment {
  object-fit: cover;
  width: 100%;
  height: 500px;
  border-radius: 45px;
  margin-top: 30px;
}

.apartment-action {
  position: absolute;
  left: 80px;
  bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .apartment-action {
    position: absolute;
    left: 30px;
    bottom: 30px;
  }

  .img-apartment {
    height: 200px;
    border-radius: 25px;
  }
}
</style>