<template>
  <div class="honor-page">
    <Header @queryDataToYear="filterToYear" @searchHonor="filterToKeyword" />
    <div style="position: relative">
      <img src="../../../assets/img/banner.png" class="banner" alt=""/>
      <div class="blur-image"></div>
    </div>
    <div class="honor-content">
      <div v-for="(honor, index) in listHonor" :key="index">
        <PageTitle
            :title="honor?.program?.program_name"
            :description="honor?.program?.program_desc"
        />
        <div v-for="(item, index) in honor.categories" :key="`${index}-${item.id}`">
          <div v-if="item.category.honor_category_type === 0 && item.newsletter.length > 0">
            <PageTitle :title="item.category.category_name" :description="item.category.category_desc"/>
            <CardStaff :list-honor-staff="item.newsletter"/>
          </div>
          <div v-if="item.category.honor_category_type === 1 && item.newsletter.length > 0">
            <PageTitle :title="item.category.category_name" :description="item.category.category_desc"/>
            <CardOKR :list-apartment="item.newsletter"/>
          </div>
          <div v-if="item.category.honor_category_type === 2 && item.newsletter.length > 0">
            <PageTitle :title="item.category.category_name" :description="item.category.category_desc"/>
            <CardApartment :list-apartment="item.newsletter"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/views/components/honors/PageTitle.vue";
import Header from "@/views/components/honors/Header.vue";
import CardStaff from "@/views/components/honors/CardStaff.vue";
import {GET_LIST_HONOR} from "@/core/services/store/service.module";
import CardOKR from "@/views/components/honors/CardOKR.vue";
import CardApartment from "@/views/components/honors/CardApartment.vue";

export default {
  components: {CardApartment, CardOKR, CardStaff, Header, PageTitle},
  mounted() {
    this.getListHonor()
  },
  data() {
    return {
      listHonor: [],
      year: '',
      keyword: '',
    }
  },
  methods: {
    filterToYear(year){
      this.getListHonor(year,'');
    },
    filterToKeyword(keyword){
      this.getListHonor('',keyword);
    },
    async getListHonor(year, keyword) {
      this.year = year;
      this.keyword = keyword;
      const payload = {
        keyword: keyword,
        year: year
      }
      await this.$store.dispatch(GET_LIST_HONOR, payload)
          .then((response) => {
            this.queryFilter();
            this.listHonor = response.data;
          }).catch((response) => {
            console.log(response.error)
          })
    },
    queryFilter() {
      this.$router.push({
        name: 'honor-private',
        query: {
          'keyword': this.keyword,
          'year': this.year
        }
      })
    }
  }
}
</script>

<style scoped>
.honor-page {
  min-height: 100vh;
  max-width: 100vw;
  font-family: "SVN-Gilroy";
}

.banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
}

.honor-content {
  background-image: linear-gradient(to bottom, #5C4DA8, #36296A);
}

.blur-image {
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), #504295);
}
@media only screen and (max-width: 767px) {
  .banner {
    height: 350px;
    object-fit: cover;
  }
  .blur-image {
    height: 140px;
    background-image: linear-gradient(to bottom,#6454B500, #6454B500, #5C4DA8);
  }
  .honor-content {
    padding: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .honor-content {
    padding: 40px 70px;
  }
  .blur-image {
    height: 180px;
    background-image: linear-gradient(to bottom,#6454B500, #6454B500, #5C4DA8);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .honor-content {
    padding: 50px 120px;
  }
  .blur-image {
    height: 200px;
    background-image: linear-gradient(to bottom,#6454B500, #6454B500, #5C4DA8);
  }
}
@media only screen and (min-width: 1281px) {
  .honor-content {
    padding: 50px 100px;
  }
  .blur-image {
    height: 250px;
    background-image: linear-gradient(to bottom,#6454B500, #6454B500, #5C4DA8);
  }
}
</style>